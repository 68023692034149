*:where(:not(html, iframe, canvas, img, svg, video, audio, svg *, symbol *)) {
	all: unset;
	display: revert;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	text-size-adjust: none;
}

a,
button {
	cursor: pointer;
}

ol,
ul,
menu,
summary {
	list-style: none;
}

img {
	display: block;
	max-block-size: 100%;
	max-inline-size: 100%;
}

table {
	border-collapse: collapse;
}

input,
textarea {
	user-select: auto;
}

textarea {
	white-space: revert;
}

meter {
	appearance: revert;
}

:where(pre) {
	all: revert;
	box-sizing: border-box;
}

::placeholder {
	color: unset;
}

:where([hidden]) {
	display: none;
}

:where([draggable="true"]) {
	-webkit-user-drag: element;
}

:where(dialog:modal) {
	all: revert;
	box-sizing: border-box;
}

::-webkit-details-marker {
	display: none;
}
