.heroSection {
	padding: 75px 0 23px;
	margin: auto;

	@media (width >= 768px) {
		padding: 0;
		width: 100%;
	}
	@media (width >= 1440px) {
		width: 1400px;
	}
}
.heroContainer {
	width: 416px;

	margin: auto;
	padding: 0 32px;

	@media (width >= 768px) {
		position: relative;
		margin: auto;

		width: 730px;
		padding: 58px 50px 166px;
		max-width: 768px;
	}
	@media (width >= 1440px) {
		max-width: 1400px;
		width: 100%;
		padding: 140px 100px 310px;
	}
}
.mobileHeader {
	position: absolute;
	display: flex;
	width: 360px;
	top: 14px;
	justify-content: space-between;
	@media (width >= 768px) {
		display: none;
	}
}

.btnBurgerMenu {
	@media (width >= 768px) {
		display: none;
	}
}

.nameWrapper {
	display: flex;
	min-height: 47px;
	@media (width >= 768px) {
		width: 435px;
	}
}

.nameHeroTitle {
	display: flex;
	align-items: baseline;
	text-align: center;
	font-variant-numeric: lining-nums proportional-nums;
	font-size: 50px;
	font-weight: 600;
	letter-spacing: 30px;
	height: 100%;
	background-image: linear-gradient(147deg, #a48e39, #95c549, #7bb022, #a9a044);
	background-clip: text;
	color: transparent;
	margin: auto;

	@media (width >= 768px) {
		margin: 0;
		font-size: 65px;
	}
	@media (width >= 1440px) {
		font-size: 110px;
		letter-spacing: 60px;
	}
}

.cuctusImgContainer {
	margin: 0 0 0 -20px;
	@media (width >= 768px) {
	}
	@media (width >= 1440px) {
		margin: 0 20px 0 -40px;
	}
}
.cactus {
	height: 47px;
	@media (width >= 768px) {
		height: 66px;
	}
	@media (width >= 1440px) {
		height: 131px;
	}
}

.decriptionText {
	margin-top: 20px;
	font-weight: 300;
	font-size: 20px;
	color: #ffffff;
	line-height: 1.3;
	text-align: center;
	width: 350px;

	@media (width >= 768px) {
		text-align: start;
	}
	@media (width >= 1440px) {
		font-size: 35px;
		line-height: 1.2;
		margin-top: 30px;
		width: 600px;
	}
}
.headerText {
	display: flex;
	gap: 3%;
	margin: 0;
	align-items: center;
}
.blockTextH {
	@media (width >= 768px) and (width < 1440px) {
		display: flex;
		gap: 6px;
		width: 400px;
	}
}
.textH {
	color: #fff;
	font-variant-numeric: lining-nums proportional-nums;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	text-align: center;
	margin: 0;

	@media (width >= 768px) {
		text-align: start;
	}
	@media (width >= 1440px) {
		font-size: 40px;
		line-height: 55px;
	}
}

.textPrickly {
	color: #fbb667;
	font-variant-numeric: lining-nums proportional-nums;
	font-size: 20px;

	font-style: normal;
	font-weight: 500;
	line-height: 26px;
	@media (width >= 768px) {
	}
	@media (width >= 1440px) {
		font-size: 40px;
		line-height: 55px;
	}
}

.heroIcon {
	width: 316px;
	height: 400px;
	@media (width >= 768px) {
		position: absolute;
		// right: calc(50px + 23px);
		right: 45px;
		top: -20px;
		z-index: -10;
	}
	@media (width >= 1440px) {
		right: 0;
		top: 0;
		width: 750px;
		height: 900px;
	}
}

.backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #152525;
	z-index: 10;
}
.modal {
	width: 200px;
	margin: auto;
	padding: 50px 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
}
.listBurgerMenuNavLink {
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;
}
