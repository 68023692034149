.mainSection {
	padding: 0 0 50px;
	margin: auto;
}

.mainContainer {
	width: 416px;
	margin: auto;
	padding: 0 32px;

	@media (width >= 768px) {
		width: 745px;
		padding: 0 50px;
	}
	@media (width >= 1440px) {
		max-width: 1400px;
		width: 100%;
		padding: 0 100px;
		position: relative;
	}
}

.mainTitle,
.blockTitle,
.blockDescription {
	color: #fff;
	font-weight: 500;
	font-size: 25px;
	line-height: 2.64;
	text-align: center;
	@media (width >= 1440px) {
		font-size: 50px;
		line-height: 1.32;
	}
}

.listItems {
	display: flex;
	flex-direction: column;
	gap: 35px;

	margin-top: 30px;
	@media (width >= 768px) {
		gap: 0;
	}
}

.itemBlock {
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (width >= 768px) {
		flex-direction: row;
		gap: 10px;

		&:nth-child(even) {
			flex-direction: row-reverse;
			& .blockTitle,
			& .blockDescription {
				text-align: end;
			}
		}
	}
	@media (width >= 1440px) {
		gap: 65px;
	}
}

.blockImg {
	width: 230px;
	@media (width >= 1440px) {
		width: 416px;
	}
}

.itemTextWrapper {
	@media (width >= 1440px) {
		max-width: 760px;
	}
}

.blockTitle {
	margin-top: 12px;
	line-height: 1.12;

	@media (width >= 768px) {
		text-align: start;
		margin-top: 0;
		margin-bottom: 12px;
	}
	@media (width >= 1440px) {
		font-size: 50px;
		line-height: 0.56;
	}
}

.blockDescription {
	margin-top: 20px;

	font-weight: 300;
	font-size: 20px;
	line-height: 1.3;

	@media (width >= 768px) {
		margin-top: 0;
		text-align: start;
	}
	@media (width >= 1440px) {
		width: 100%;
		font-size: 40px;
		line-height: 1.125;
	}
}
