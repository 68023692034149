.container {
	@media (width<768px) {
		padding-top: 14px;
	}
}

.buttonStyle {
	width: 165px;
	height: 62px;
	cursor: pointer;
	color: #fff;
	font-size: 17px;
	border-radius: 1rem;
	border: none;
	position: relative;
	background: #100720;
	transition: 0.1s;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px;
}

.buttonStyle::after {
	content: "";
	width: 100%;
	height: 100%;
	background-image: radial-gradient(
		circle farthest-corner at 10% 20%,
		rgba(62, 250, 205, 0.6) 17.8%,
		rgba(2, 245, 255, 1) 100.2%
	);
	filter: blur(15px);
	z-index: -1;
	position: absolute;
	left: 0;
	top: 0;
}

.buttonStyle:active {
	transform: scale(0.9) rotate(3deg);
	background: radial-gradient(
		circle farthest-corner at 10% 20%,
		rgba(65, 198, 102, 0.6) 17.8%,
		rgba(2, 245, 255, 1) 100.2%
	);
	transition: 0.5s;
}

.textCalculateReward {
	color: aquamarine;
	margin: 15px;
	font-size: 23px;
}

.buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin-top: 50px;
}

.mobileHeader {
	display: flex;
	width: 360px;
	justify-content: space-between;
	margin: auto;

	@media (width >= 768px) {
		display: none;
	}
}
