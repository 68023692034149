.docsSection {
	background-color: #132221;
	padding-top: 20px;
}
.docsContainer {
	display: flex;
	color: white;
	margin: auto;
	width: 95%;
	align-items: baseline;
	justify-content: flex-end;
}

.docsButtonContainer {
	background-color: #529d944d;
	width: auto;
	height: 90vh;
	margin-right: 15px;
	border-top-right-radius: 50px;
}

.headerModal {
	display: flex;
	flex-direction: row;
	align-items: center;
	@media (width >= 768px) {
		display: none;
	}
}

.btnDocBurgerMenu {
	padding: 28px 38px 0 28px;
}

.linkComeBack {
	display: flex;
	flex-direction: row;
	height: 28px;
	gap: 10px;
	align-items: center;
	margin-top: 28px;
	font-size: 19px;
	color: #b8dddc;
	line-height: 1.5;
}

.sidebar {
	height: 90vh;
	width: 240px;
	overflow-y: auto;
	background-color: #132221;
	box-sizing: border-box;
	@media (width<768px) {
		background-color: transparent;
		padding: 28px 8px;
	}
	@media (width>= 1440px) {
		padding-left: 70px;
	}
}

.mainContent {
	justify-content: center;
	align-items: center;
	overflow-y: auto;
	width: 90%;
}

.chapter,
.topic {
	margin-bottom: 30px;
	list-style-type: none;
	cursor: pointer;
}

.chapter {
	font-weight: bold;
	font-size: 18px;
	cursor: pointer;
	color: white; // обычный цвет

	&.active {
		color: #07f8b5; // цвет активной или выбранной главы
	}

	@media (width < 768px) {
		color: #5bb7b4;
	}
}

.topic {
	font-weight: normal;
	font-size: 16px;
	margin-left: 10px;
	width: 80%;
}

a {
	text-decoration: none;
	color: white;
	@media (width < 768px) {
		color: #5bb7b4;
	}

	&:hover,
	&.active {
		color: #07f8b5;
	}
}

ul {
	list-style-type: none;
	padding: 0;
}
.textH {
	font-size: 31px;
	font-weight: 800;
	color: #81ffe1;
	padding: 10px 0;
	width: 80%;
	@media (width >= 768px) {
		padding: 20px;
	}
	// @media (width >= 1440px) {
	// 	width: 1440px;
	// }
}

.textChapter {
	font-size: 25px;
	font-weight: 500;
	color: #a3d7b8;
	width: 300px;
	padding: 10px 0;
	width: 90%;
	@media (width >= 768px) {
		padding: 20px;
	}

	@media (width>=1440px) {
		width: 855px;
	}
}

.text {
	font-size: 21px;
	font-weight: 400;
	padding: 10px;
	width: 80%;
	color: #d2ffef;
}

.textSing {
	color: #3be4b5;
	font-family: "Sacramento", cursive;
	font-size: 65px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	margin: 70px;
	margin-left: 0 auto;
	width: 100%;
}
