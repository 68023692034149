.header {
	background-color: #0e201f;
	width: 100%;
}

.wrapperHeader {
	display: none;
	@media (width >= 768px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 60px;
		margin: auto;
		padding: 0 30px 0 50px;
	}
	@media (width >= 1440px) {
		width: 100%;
		max-width: 1400px;
		padding: 0 100px;
		gap: 90px;
	}
}

.headerNav {
	@media (width >= 768px) {
		width: 425px;
	}

	@media (width >= 1440px) {
		width: 836px;
	}
}

.listContainer {
	display: flex;
	justify-content: center;
	gap: 30px;
	list-style-type: none;
	padding: 0;
	margin: 0;
	align-items: center;
	@media (width >= 1440px) {
		gap: 40px;
	}
}

.navLinkItem {
	font-weight: 400;
	line-height: 1.12;
	flex-direction: row;

	@media (width >= 768px) {
		font-size: 16px;
	}
	@media (width >= 1440px) {
		font-size: 25px;
	}
}

.text {
	color: aliceblue;
	text-decoration: none;
}

.box {
	display: flex;
	width: 135px;
	align-items: center;
	padding: 0;
	gap: 5px;

	@media (width >= 768px) {
		justify-content: space-between;
		gap: 5px;
	}
	@media (width >= 1440px) {
		width: 185px;
		justify-content: space-between;
		gap: 13px;
	}
}

.logo {
	width: 35px;
	height: 35px;
	@media (width >= 1440px) {
		width: 50px;
		height: 50px;
	}
}
.logoText {
	font-weight: 500;
	font-size: 25px;

	line-height: 0.8;
	color: #ffffff;
	text-transform: uppercase;
	margin: 0;

	@media (width >= 1440px) {
		font-size: 35px;
	}
}
